/* /src/components/ProcessedFiles.css */

/* Processed Files Section Container */
.processed-files-section {
    width: 100%;
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: var(--secondary-color);
    border-radius: 12px;
    box-shadow: var(--card-shadow);
    transition: background-color var(--transition-duration), box-shadow var(--transition-duration);
}

/* Section Title */
.section-title {
    font-size: 28px;
    font-weight: bold;
    color: var(--highlight-color);
    margin-bottom: 25px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    border-bottom: 2px solid var(--highlight-color);
    padding-bottom: 10px;
}

/* Container for Processed Files */
.processed-files-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

/* Individual File Card */
.processed-file-card {
    background-color: #1a1a1a;
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 20px;
    box-shadow: var(--card-shadow);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform var(--transition-duration), box-shadow var(--transition-duration), background-color var(--transition-duration);
}

/* Hover Effect for File Card */
.processed-file-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--card-hover-shadow);
    background-color: #262626;
}

/* File Information Section */
.file-info {
    margin-bottom: 15px;
}

/* File Title */
.file-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--highlight-color);
    word-wrap: break-word;
}

/* File Date */
.file-date {
    font-size: 14px;
    color: #ccc;
}

/* Download Button */
.download-button {
    align-self: flex-start;
    background-color: var(--highlight-color);
    border: none;
    color: var(--primary-color);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.download-button:hover {
    background-color: var(--hover-color);
    transform: translateY(-2px);
}

/* No Processed Files Message */
.no-processed-files {
    color: var(--text-color);
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
}

/* Responsive Grid Adjustments */
@media (max-width: 768px) {
    .processed-files-container {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

@media (max-width: 480px) {
    .processed-files-container {
        grid-template-columns: 1fr;
    }
}
