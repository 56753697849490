.sign-in-button {
    background-color: #1e1e1e;
    /* Google blue */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 2px;
    font-size: 0.9em;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    outline: none;
}

.sign-in-button:hover {
    background-color: #357ae8;
    /* Darker Google blue */
}