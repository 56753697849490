/* /src/App.css */
:root {
  /* Updated Color Variables for Elegant Black and White Design */
  --primary-color: #000000; /* Pure Black */
  --secondary-color: #1a1a1a; /* Very Dark Gray */
  --accent-color: #ffffff; /* Pure White */
  --highlight-color: #f0f0f0; /* Light Gray for Highlights */
  --hover-color: #0892a2; /* Dark Gray for Hover Effects */
  --text-color: #ffffff; /* Pure White for Text */
  --link-color: #ffffff; /* Pure White for Links */
  --link-hover-color: #f0f0f0; /* Light Gray on Link Hover */
  --border-color: #444444; /* Medium Gray for Borders */
  --card-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Subtle White Shadow */
  --card-hover-shadow: 0 6px 12px rgba(6, 207, 180, 0.2); /* Enhanced Shadow on Hover */
  --transition-duration: 0.3s; /* Slightly Faster Transition */
  --font-family: 'Roboto', sans-serif;
}

/* Ensure html and body allow scrolling */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: auto;   /* Allow vertical scrolling */
}

body {
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--primary-color);
}

/* Adjust the .App container to allow natural expansion */
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the App takes at least the full viewport height */
}

/* Modify .middle-content to remove restrictive properties */
.middle-content {
  /* Removed flex-grow and overflow properties */
  /* flex-grow: 1; */
  /* overflow: auto; */

  /* Add padding for spacing */
  padding: 20px;
  width: 100%; /* Ensure it takes full width */
  box-sizing: border-box;
}


/* Global Styles */
* {
  box-sizing: border-box;
}

button,
select {
  padding: 12px 24px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--secondary-color);
  color: var(--accent-color);
  cursor: pointer;
  transition: background-color var(--transition-duration), transform var(--transition-duration), color var(--transition-duration);
  font-size: 1rem;
  font-weight: bold;
}

button:hover,
select:hover {
  background-color: var(--hover-color);
  color: var(--highlight-color);
  transform: scale(1.05);
}

input[type="file"],
input[type="text"],
input[type="password"] {
  margin: 20px 0;
  padding: 12px;
  width: 100%;
  max-width: 600px;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  background-color: #1a1a1a; /* Slightly lighter than primary */
  color: var(--accent-color);
  transition: border-color var(--transition-duration);
}

input[type="file"]:focus,
input[type="text"]:focus,
input[type="password"]:focus {
  border-color: var(--highlight-color);
  outline: none;
}

.logo-nav-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Updated Logo Button Styles */
.logo-play-button {
  background: none; /* Remove default button background */
  border: none;     /* Remove default button border */
  padding: 0;       /* Remove default button padding */
  cursor: pointer;  /* Change cursor to pointer on hover */
  display: flex;    /* Ensure proper alignment */
  align-items: center;
  z-index: 1000;    /* Ensure it's on top */
}

.logo-play-button img {
  width: 60px; 
  height: 60px;
  border-radius: 50%;
  border: 3px solid var(--highlight-color);
  transition: transform var(--transition-duration);
}

.logo-play-button:hover img {
  transform: rotate(360deg);
}

/* Focus Styles for Accessibility */
.logo-play-button:focus {
  outline: 2px solid var(--highlight-color);
  outline-offset: 4px;
}

.navbar {
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--secondary-color);
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
  transition: background-color var(--transition-duration), box-shadow var(--transition-duration);
}

.navbar.scrolled {
  background: rgba(26, 26, 26, 0.95); /* Slight transparency */
  box-shadow: 0 6px 8px rgba(255, 255, 255, 0.2);
}

.navbar div,
.footer,
.video-info {
  color: var(--text-color);
  cursor: pointer;
}

.about-link {
  margin: 0 15px;
  color: var(--link-color);
  text-decoration: none;
  position: relative;
  transition: color var(--transition-duration);
}

.about-link::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  display: block;
  background: var(--highlight-color);
  transition: width var(--transition-duration);
  left: 0;
  bottom: -5px;
}

.about-link:hover {
  color: var(--link-hover-color);
}

.about-link:hover::after {
  width: 100%;
}

.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.email-auth {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sign-in-button {
  padding: 10px 20px;
  background-color: var(--highlight-color);
  color: var(--primary-color);
  border-radius: 8px;
  transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.sign-in-button:hover {
  background-color: var(--hover-color);
  transform: translateY(-3px);
}

.video-container {
  padding: 20px;
}

.video-iframe {
  width: 80%;
  height: 40vh;
  border: none;
  border-radius: 12px;
  background-color: #000000;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
}

.video-carousel {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  max-width: 100%;
  padding: 20px;
}

.video-carousel iframe,
.video-carousel video {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 12px;
  background-color: #000000;
  transition: transform var(--transition-duration);
}

.video-carousel iframe:hover,
.video-carousel video:hover {
  transform: scale(1.05);
}

.video-carousel::-webkit-scrollbar {
  height: 8px;
}

.video-carousel::-webkit-scrollbar-thumb {
  background: var(--highlight-color);
  border-radius: 4px;
}

.video-carousel::-webkit-scrollbar-track {
  background: transparent;
}

.footer {
  color: var(--text-color);
  padding: 20px 40px;
  text-align: center;
  background: var(--secondary-color);
  position: relative;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.footer-links a,
.footer-social-media a {
  color: var(--link-color);
  text-decoration: none;
  transition: color var(--transition-duration);
}

.footer-links a:hover,
.footer-social-media a:hover {
  color: var(--link-hover-color);
}

.pricing-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 20px; /* Reduced padding */
  gap: 80px; /* Increased gap from 20px to 40px */
  margin: 20vh 0; /* Adjusted margin */
  border-radius: 20px;
  background: var(--secondary-color);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

/* Enhanced Pricing Card */
.pricing-card {
  background: linear-gradient(135deg, #1f1f1f, #2c2c2c);
  color: var(--text-color);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 30px; /* Reduced padding */
  width: 100%;
  max-width: 350px; /* Reduced max-width */
  text-align: center;
  transition: transform var(--transition-duration), box-shadow var(--transition-duration), background 0.5s ease;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #333;
  /* Remove any margin if present */
  margin: 0; 
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
  background: linear-gradient(135deg, #2c2c2c, #1f1f1f);
}

/* Pricing Card Header */
.pricing-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px; /* Reduced height */
  background: linear-gradient(90deg, #0f0c29, #302b63, #24243e);
}

.pricing-card h2 {
  color: #00d1ff;
  font-size: 22px; /* Reduced font size */
  margin-bottom: 10px; /* Reduced margin */
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.pricing-card .price {
  color: #00d1ff;
  font-size: 24px; /* Reduced font size */
  margin: 10px 0; /* Reduced margin */
  font-weight: bold;
}

.pricing-card .old-price {
  color: #7f8c8d;
  font-size: 16px; /* Reduced font size */
  text-decoration: line-through;
}

.pricing-card p {
  color: #ccc;
  font-size: 14px; /* Reduced font size */
  margin-bottom: 20px; /* Reduced margin */
  line-height: 1.4;
}

/* Enhanced List Items */
.pricing-card ul {
  list-style: none;
  padding: 0;
  margin: 15px 0; /* Reduced margin */
}

.pricing-card li {
  color: #ccc;
  font-size: 14px; /* Reduced font size */
  line-height: 1.6;
  text-align: left;
  position: relative;
  padding-left: 25px; /* Reduced padding */
  margin-bottom: 10px; /* Reduced margin */
  transition: color 0.3s ease;
}

.pricing-card li::before {
  content: '⚙️'; /* Changed icon to a gear for AI theme */
  color: #00d1ff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px; /* Reduced font size */
}

.pricing-card li:hover {
  color: #00d1ff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .pricing-container {
    flex-direction: column;
    align-items: center;
    gap: 30px; /* Adjusted gap for smaller screens */
  }

  .pricing-card {
    width: 90%;
  }

  .pricing-card h2 {
    font-size: 20px; /* Further reduced font size */
  }

  .pricing-card .price {
    font-size: 22px; /* Further reduced font size */
  }

  .pricing-card p {
    font-size: 13px; /* Further reduced font size */
  }

  .pricing-card li {
    font-size: 13px; /* Further reduced font size */
  }

  .contact-button {
    position: relative; /* Ensure positioning */
    z-index: 10;
    font-size: 14px;
    padding: 10px 20px; /* Adjusted padding */
  }
}

/* Contact Button Enhancements */
.contact-button {
  background: linear-gradient(135deg, #00d1ff, #007ea7);
  color: var(--primary-color);
  padding: 10px 20px; /* Reduced padding */
  border: none;
  border-radius: 25px;
  font-size: 14px; /* Reduced font size */
  cursor: pointer;
  transition: background 0.3s ease, transform var(--transition-duration);
  box-shadow: 0 4px 10px rgba(0, 209, 255, 0.3);
}

.contact-button:hover {
  background: linear-gradient(135deg, #007ea7, #00d1ff);
  transform: translateY(-3px);
}

/* Additional Enhancements for AI Style */
.pricing-card::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(0, 209, 255, 0.2), transparent 70%);
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none; /* Prevent blocking interactions */
}

.pricing-card:hover::after {
  opacity: 1;
}



.multivoice-toggle {
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;
}

.multivoice-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 5px;
  color: var(--text-color);
}

.multivoice-label input {
  margin-right: 5px;
  width: 18px;
  height: 18px;
  accent-color: var(--highlight-color);
}

.info-icon {
  color: var(--highlight-color);
  cursor: pointer;
  font-size: 1.5rem;
  transition: color var(--transition-duration);
}

.info-icon:hover {
  color: var(--hover-color);
}

/* Voice Selection Dropdown */
.voice-selection-dropdown {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.voice-selection-dropdown select,
.language-selection-container select {
  background-color: var(--secondary-color);
  border: 2px solid var(--highlight-color);
  color: var(--accent-color);
  padding: 10px 20px;
  border-radius: 8px;
  margin-right: 10px;
  transition: border-color var(--transition-duration), background-color var(--transition-duration);
}

.voice-selection-dropdown select:hover,
.language-selection-container select:hover {
  border-color: var(--hover-color);
}

.voice-selection-dropdown button {
  background-color: var(--highlight-color);
  color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.voice-selection-dropdown button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.voice-selection-dropdown button:hover:not(:disabled) {
  background-color: var(--hover-color);
  transform: translateY(-2px);
}

.language-selection-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.language-selection-container > div {
  margin: 0;
  display: flex;
  align-items: center;
}

.language-selection-container label {
  margin-right: 4px;
  font-size: 1rem;
  color: var(--text-color);
}

.voice-selection-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0 15px;
}

.voice-selection-container select {
  font-size: 16px;
}

.reply-button {
  background-color: var(--secondary-color);
  border: 2px solid var(--highlight-color);
  color: var(--highlight-color);
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color var(--transition-duration), color var(--transition-duration), transform var(--transition-duration);
  font-size: 0.9rem;
}

.reply-button:hover {
  background-color: var(--hover-color);
  color: var(--primary-color);
  transform: translateY(-2px);
}




.upload-container,
.voice-selection-container,
.url-upload,
.file-upload {
  width: 100%;
}

.upload-inputs,
.voice-selection-container {
  display: flex;
  flex-wrap: wrap;
}

.upload-inputs {
  justify-content: center;
  align-items: center;
  height: 100%;
}

.upload-container {
  padding-bottom: 30px;
}

.upload-header h3 {
  text-align: center;
  margin-bottom: 20px;
  color: var(--highlight-color);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5),
               0 0 20px rgba(255, 255, 255, 0.5),
               0 0 30px rgba(255, 255, 255, 0.5),
               0 0 40px rgba(255, 255, 255, 0.5);
}

.file-upload,
.url-upload {
  padding: 8px;
}

.file-upload label,
.url-upload button {
  background-color: var(--highlight-color);
  color: var(--primary-color);
  border: none;
  cursor: pointer;
  border-radius: 8px;
  padding: 10px 20px;
  transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.file-upload label:hover,
.url-upload button:hover {
  background-color: var(--hover-color);
  transform: translateY(-2px);
}

.file-name-display,
.file-requirements {
  text-align: center;
  color: #aaa;
  font-size: 0.8em;
  margin-top: 4px;
}

.middle-content {
  /* Removed flex-grow and overflow */
  /* flex-grow: 1; */
  /* overflow: auto; */

  /* Add padding or margin if needed for spacing */
  padding: 20px;
}

.video-info {
  background-color: #1a1a1a;
  color: var(--text-color);
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  box-shadow: var(--card-shadow);
  margin: 20px 0;
}

.video-preview,
.video-thumbnail {
  margin: 20px auto;
  width: 90%;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
}

.video-preview label,
.video-thumbnail label {
  display: block;
  margin-bottom: 12px;
  color: var(--highlight-color);
}

.download-button {
  background-color: var(--highlight-color);
  border: none;
  color: var(--primary-color);
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 6px 4px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.download-button:hover {
  background-color: var(--hover-color);
  transform: translateY(-2px);
}

.iframe-container {
  display: flex;
  gap: 15px;
  padding: 15px;
  align-items: center;
}

.iframe-container iframe {
  height: 220px;
  width: 320px;
  border-radius: 12px;
  transition: transform var(--transition-duration);
}

.iframe-container iframe:hover {
  transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .video-iframe {
    height: 40vh;
  }
}

@media (min-width: 1200px) {
  .video-iframe {
    height: 40vh;
  }
}

@media (min-width: 1024px) {
  .video-iframe {
    height: 35vh;
  }
}

@media (min-width: 768px) {
  .video-iframe {
    height: 40vh;
  }
}

@media (max-width: 900px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 20px;
  }

  .logo-nav-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

  .logo-play-button {
    order: 1;
    margin-bottom: 15px;
  }

  .about-link:nth-child(-n+4) {
    order: 2;
  }

  .logo-nav-container a:not(:nth-child(-n+4)),
  .logo-nav-container select {
    order: 3;
    margin-top: 10px;
  }

  .about-link {
    margin: 5px 15px;
  }

  .auth-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
  }

  .email-auth {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
  }

  .sign-in-button {
    width: auto;
  }

  .auth-buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
  }

  .auth-buttons-container button {
    flex: 1;
    width: auto;
  }
}

@media (max-width: 768px) {
  .upload-inputs {
    flex-direction: column;
    align-items: center;
    padding: 5%;
  }

  .voice-selection select {
    margin: 10px 10px 0 10px;
    width: calc(100% - 20px);
  }

  .voice-selection {
    margin: 20px 0 10px 0;
  }

  button,
  select {
    width: auto;
    max-width: 100%;
  }

  .voice-selection-dropdown {
    flex-direction: column;
    align-items: flex-start;
  }

  .voice-selection-dropdown select,
  .voice-selection-dropdown button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* Additional Components */
.terms-container {
  box-shadow: var(--card-shadow);
  margin: 100px auto;
  max-width: 800px;
  text-align: left;
  padding: 30px 70px 50px 70px;
  background-color: var(--secondary-color);
  border-radius: 12px;
  
  /* Line spacing for better readability */
  line-height: 1.6;
}

/* Headers within .terms-container */
.terms-container h1,
.terms-container h2,
.terms-container h3,
.terms-container h4,
.terms-container h5,
.terms-container h6 {
  margin-top: 24px;    /* Space above headers */
  margin-bottom: 16px; /* Space below headers */
}

/* Paragraphs within .terms-container */
.terms-container p {
  margin-top: 16px;    /* Space above paragraphs */
  margin-bottom: 16px; /* Space below paragraphs */
}



.video-slider-container .slick-dots li button:before {
  color: var(--highlight-color);
  font-size: 16px;
}

.video-slider-container .slick-dots li.slick-active button:before {
  color: var(--hover-color);
  font-size: 16px;
}

.hero-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--highlight-color);
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
}

.footer {
  /* Already defined earlier */
}

/* Progress Bar Styles */
.progress-bar-wrapper {
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

.do-not-refresh-text {
  font-size: 22px;
  color: var(--accent-color);
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

/* Download Button Styles */
.download-button {
  background-color: var(--highlight-color);
  border: none;
  color: var(--primary-color);
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 6px 4px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.download-button:hover {
  background-color: var(--hover-color);
  transform: translateY(-2px);
}

/* Ensure footer stays at the bottom */
.footer {
  margin-top: auto;
}



/* Add this at the top or appropriate section in App.css */

@keyframes rotateGlobe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Define the rotation keyframes */
@keyframes rotateGlobe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.motivation-image img {
  width: 300px; /* Adjust size as needed */
  height: 300px; /* Adjust size as needed */
  border-radius: 50%; /* Makes the image circular */
  transition: transform var(--transition-duration), animation-play-state var(--transition-duration);
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
  
  /* Initial animation setup */
  animation: rotateGlobe 10s linear infinite;
}

/* Pause rotation on hover */
.motivation-image img:hover {
  animation-play-state: paused; /* Pauses the rotation */
  transform: scale(1.05); /* Optional: Slightly scale up on hover */
}

/* Ensure animation is running when not hovered */
.motivation-image img:not(:hover) {
  animation-play-state: running; /* Resumes the rotation */
  transform: scale(1); /* Reset scale */
}

/* Define the keyframes for rotation */
@keyframes rotateGlobe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
