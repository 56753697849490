/* src/components/ProgressBar.css */

.progress-bar-wrapper {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    position: relative;
}

.progress-bar-background {
    width: 100%;
    height: 20px;
    background: rgba(240, 240, 240, 0.2);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2), 0 0 10px rgba(37, 117, 252, 0.5);
    animation: pulse 2s infinite;
}

.progress-bar-fill {
    height: 100%;
    width: 0%;
    background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);
    border-radius: 10px 0 0 10px;
    transition: width 0.2s ease-in-out;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(106, 17, 203, 0.5), 0 0 20px rgba(37, 117, 252, 0.5);
}

.progress-bar-fill::after {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    animation: shimmer 2s infinite;
}

@keyframes shimmer {
    0% {
        left: -50%;
    }
    100% {
        left: 100%;
    }
}

.progress-bar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.progress-percentage {
    font-size: 0.9rem;
    color: #ffffff;
    font-weight: bold;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

/* Pulse Animation for Background */
@keyframes pulse {
    0% {
        background-color: rgba(240, 240, 240, 0.2);
    }
    50% {
        background-color: rgba(240, 240, 240, 0.3);
    }
    100% {
        background-color: rgba(240, 240, 240, 0.2);
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .progress-bar-wrapper {
        max-width: 90%;
    }

    .progress-bar-background {
        height: 15px;
    }

    .progress-percentage {
        font-size: 0.8rem;
    }
}
