/* /src/components/Tooltip.css */

.tooltip-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(15, 32, 39, 0.8); /* Dark overlay with slight transparency */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.5s ease;
}

.tooltip-content {
    background: #1a1a2e; /* Dark background for tooltip */
    color: #00c6ff; /* Neon blue text */
    padding: 25px 40px 25px 25px; /* Increased padding-right to prevent overlap */
    border-radius: 12px;
    max-width: 350px;
    position: relative;
    text-align: left; /* Changed to left alignment for better readability */
    box-shadow: 0 0 20px rgba(0, 198, 255, 0.5);
    border: 2px solid #00c6ff; /* Neon border */
    animation: slideIn 0.5s ease;
    box-sizing: border-box; /* Ensure padding is included in width */
}

.tooltip-close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    background: transparent;
    border: none;
    color: #00c6ff;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s;
    padding: 0; /* Remove any default padding */
    line-height: 1; /* Ensure the button doesn't take extra space */
}

.tooltip-close-button:hover {
    transform: rotate(90deg);
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

/* Prevent text from overlapping the close button */
.tooltip-content p {
    margin: 0;
    padding-right: 20px; /* Additional padding to the right to ensure text doesn't go under the close button */
    line-height: 1.5; /* Improve readability */
}

/* Responsive Adjustments */
@media (max-width: 400px) {
    .tooltip-content {
        padding: 20px 35px 20px 20px; /* Adjust padding for smaller screens */
    }

    .tooltip-close-button {
        top: 8px;
        right: 10px;
        font-size: 20px;
    }

    .tooltip-content p {
        padding-right: 15px;
    }
}
