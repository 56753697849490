/* /src/Translate.css */

/* Drag-and-Drop Area Styles */
.dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed var(--highlight-color);
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
    transition: border-color var(--transition-duration), background-color var(--transition-duration);
    background-color: var(--secondary-color);
    color: var(--highlight-color);
    position: relative;
}

.dropzone.active {
    border-color: var(--hover-color);
    background-color: var(--hover-color);
    color: var(--primary-color);
}

.upload-icon {
    font-size: 2rem;
    margin-top: 10px;
}

.error-message {
    color: #ff4d4f;
    margin-top: 10px;
    font-size: 0.9rem;
}

.file-details {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.remove-file-button {
    background-color: #ff4d4f;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.remove-file-button:hover {
    background-color: #d9363e;
    transform: translateY(-2px);
}

/* Or Separator Styles */
.or-separator {
    margin: 15px 0;
    color: var(--text-color);
    font-weight: bold;
}

/* Zips Container Styles */
.zips-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.zip-card {
    background-color: var(--secondary-color);
    color: var(--text-color);
    box-shadow: var(--card-shadow);
    padding: 20px;
    border-radius: 12px;
    width: 200px;
    text-align: center;
    transition: transform var(--transition-duration), box-shadow var(--transition-duration);
}

.zip-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--card-hover-shadow);
}

.zip-title {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: var(--highlight-color);
}

.download-button {
    background-color: var(--highlight-color);
    border: none;
    color: var(--primary-color);
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.download-button:hover {
    background-color: var(--hover-color);
    transform: translateY(-2px);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .dropzone {
        width: 90%;
    }

    .zip-card {
        width: 90%;
    }
}

/* /src/Translate.css */

/* Container Styles */
.translate-container {
    max-width: 800px;
    width: 100%;
    padding: 2% 5%;
    margin: 20px auto;
    background: var(--secondary-color);
    border-radius: 12px;
    box-shadow: var(--card-shadow);
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Upload Container */
.translate-upload-container {
    width: 100%;
    margin-bottom: 20px;
}

/* Upload Inputs */
.translate-upload-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Dropzone Styles */
.translate-dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed var(--highlight-color);
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
    transition: border-color var(--transition-duration), background-color var(--transition-duration);
    background-color: var(--secondary-color);
    color: var(--highlight-color);
    position: relative;
}

.translate-dropzone.active {
    border-color: var(--hover-color);
    background-color: var(--hover-color);
    color: var(--primary-color);
}

.translate-upload-icon {
    font-size: 2rem;
    margin-top: 10px;
}

/* Error Message */
.translate-error-message {
    color: #ff4d4f;
    margin-top: 10px;
    font-size: 0.9rem;
}

/* File Details */
.translate-file-details {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    text-align: center;
}

.translate-remove-file-button {
    background-color: #ff4d4f;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.translate-remove-file-button:hover {
    background-color: #d9363e;
    transform: translateY(-2px);
}

/* Language Selector Container */
.translate-language-selector-container {
    width: 100%;
    margin-bottom: 20px;
}

/* Voice and Ethical Translation Selection */
.translate-voice-ethical-selection {
    display: flex;
    flex-direction: row; /* Align horizontally */
    align-items: center; /* Vertically center items */
    justify-content: space-between; /* Distribute space between elements */
    width: 100%;
    margin-bottom: 20px;
    gap: 20px; /* Space between the toggles */
}

/* Multivoice Toggle */
.translate-multivoice-toggle,
.translate-ethical-translation-toggle {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between checkbox and label/icon */
}

/* Labels */
.translate-multivoice-label,
.translate-ethical-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    color: var(--text-color);
}

.translate-multivoice-label input,
.translate-ethical-label input {
    margin-right: 5px;
    width: 18px;
    height: 18px;
    accent-color: var(--highlight-color);
}

/* Info Icons */
.translate-info-icon {
    color: var(--highlight-color);
    cursor: pointer;
    font-size: 1.5rem;
    transition: color var(--transition-duration);
}

.translate-info-icon:hover {
    color: var(--hover-color);
}

/* Voice Selection and Replay */
.translate-voice-selection-dropdown-replay {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 20px;
}

/* Adjust the width of the Select a Voice dropdown */
.translate-voice-select {
    flex: 1;
    max-width: 250px; /* Set a maximum width */
    padding: 12px;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--primary-color);
    color: var(--accent-color);
    font-size: 1rem;
    transition: border-color var(--transition-duration);
}

.translate-voice-select:focus {
    border-color: var(--highlight-color);
    outline: none;
}

/* Replay Button Styles */
.translate-replay-button {
    flex-shrink: 0; /* Prevent the button from shrinking */
    padding: 10px 20px;
    background-color: var(--secondary-color);
    color: var(--accent-color);
    border: 2px solid var(--border-color);
    border-radius: 8px;
    cursor: pointer;
    transition: background-color var(--transition-duration), transform var(--transition-duration);
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
}

.translate-replay-button:hover {
    background-color: var(--hover-color);
    transform: translateY(-2px);
}

.translate-replay-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

/* Progress Bar Wrapper */
.translate-progress-bar-wrapper {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
}

/* Do Not Refresh Text */
.translate-do-not-refresh-text {
    font-size: 22px;
    color: var(--accent-color);
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
}

.translate-estimated-time {
    display: block;
    margin-top: 5px;
    font-size: 1rem;
    color: var(--highlight-color);
}

/* Translate Button */
.translate-button {
    padding: 12px 24px;
    background-color: var(--highlight-color);
    color: var(--primary-color);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color var(--transition-duration), transform var(--transition-duration);
    display: flex;
    align-items: center;
    gap: 8px;
}

.translate-button:hover {
    background-color: var(--hover-color);
    transform: translateY(-2px);
}

.translate-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .translate-container {
        padding: 5% 2%;
    }

    .translate-voice-ethical-selection {
        flex-direction: column; /* Stack vertically on small screens */
        align-items: flex-start;
        gap: 10px;
    }

    .translate-voice-selection-dropdown-replay {
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
    }

    .translate-replay-button {
        width: 100%;
    }
}
