.motivation-section {
    /* background-color: #000000; */
    color: white;
    /* padding: 50px 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  
  .motivation-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    /* justify-content: center; */
    flex-direction: row; /* Ensure items are in a row */
  }
  
  .motivation-text {
    flex: 1;
    /* padding-right: 20px; */
    color:#ca4d4d;
    /* color:#dd1c2c; */
  }
  
  .motivation-text h2 {
    font-size: 3.5em;
    margin-bottom: 20px;
    color: #ffd700;
    text-shadow: 
      -1px -1px 0 #000,  
       1px -1px 0 #000,
      -1px  1px 0 #000,
       1px  1px 0 #000; /* Add border effect */
  }
  
  .youtube-text {
    /* color: #e44b4b;  */
    color: #ca4d4d; 
    text-shadow: 
      -1px -1px 0 #000,  
       1px -1px 0 #000,
      -1px  1px 0 #000,
       1px  1px 0 #000; /* Add border effect */
  }
  
  .motivation-text .green-text {
    /* color: #ffd700;  */
    color: #03aeb4; /* Solid golden color */
    font-weight: bold;
    text-shadow: 
      -1px -1px 0 #000,  
       1px -1px 0 #000,
      -1px  1px 0 #000,
       1px  1px 0 #000; /* Add border effect */
  }
  
  .motivation-text p {
    font-size: 1.2em;
    /* margin-bottom: 40px; */
    text-shadow: 
      -1px -1px 0 #000,  
       1px -1px 0 #000,
      -1px  1px 0 #000,
       1px  1px 0 #000; 
  }
  
  .motivation-button {
    background-color: #ffb400;
    color: #1a202c;
    padding: 15px 30px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .motivation-button:hover {
    background-color: #e0a800;
  }
  
  .motivation-image {
    /* flex: 1; */
    display: flex;
    justify-content: right;

  }
  
  .motivation-image img {
    max-width: 100%;
    height: auto;
    max-height: 350px; /* Customize the size as needed */
    border: none; /* Ensure no borders */
    box-shadow: none; /* Ensure no shadows */
  }
  
  @media (max-width: 768px) {
    .motivation-section {
      padding: 30px 10px;
    }
  
    .motivation-content {
      flex-direction: column; /* Stack items vertically on smaller screens */
    }
  
    .motivation-text {
      padding-right: 0;
      text-align: center;
    }
  
    .motivation-text h2 {
      font-size: 2em;
    }
  
    .motivation-text p {
      font-size: 1em;
      margin-bottom: 20px;
    }
  
    .motivation-image {
      margin-top: 20px; /* Add some space between text and image */
    }
  
    .motivation-image img {
      max-height: 300px; /* Adjust the size for smaller screens */
      min-height: 150px; /* Ensure a minimum height on smaller screens */
    }
  }
  
  .highlight {
    color: gold;
    font-weight: bold; /* Optional: makes the highlighted text bold */
  }
  